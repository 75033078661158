.App {
  text-align: center;
  height: 100%;
  background: #ddd;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  height: 100%;
  margin: 0;
  /* font-size: 14px; */
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
  overflow: hidden;
  /* margin-bottom: 10px; */
  border: 1px solid #ddd;
}

.box .row.footer {
  flex: 0 1 40px;
}

.parent-height {
  height: 100%;
}

.chat-unread {
  height: 14px;
  width: 14px;
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 95px;
  background: none;
  border: 50%;
  color: #c7ccff;
}
.chat-pending {
  height: 14px;
  width: 14px;
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 95px;
  background: none;
  border-radius: 50%;
  color: #7480ff !important;
}
.chat-read {
  height: 14px;
  width: 14px;
  display: inline-block;
  position: absolute;
  top: 14px;
  color: #ccc;
  right: 95px;
  background: none;
  border-radius: 50%;
}

.nav-bg {
  background: #4483d6;
  color: #ddd;
}
.message-loader,
.message-loader:after {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: white;
}
.message-loader {
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.message-error {
  color: #d8000c;
  background-color: #ffbaba;
  background-image: url('https://i.imgur.com/GnyDvKN.png');
}

.checkmark {
  font-size: 20px;
  animation: check 3s ease;
  animation-delay: 1s;
  color: #0084ff;
  animation-fill-mode: forwards;
  position: relative;
}

@keyframes check {
  20% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    width: 0;
    visibility: hidden;
    height: 0;
  }
}
