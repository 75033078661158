html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: 'Segoe UI Local', 'Segoe UI Web (West European)' !important; */
}
* {
  /* font-family: 'Segoe UI Local', 'Segoe UI Web (West European)' !important; */
}
div,
article,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
strong,
i,
em,
mark,
small,
del,
ins,
sub,
sup,
a,
button,
img {
  /* font-family: 'Segoe UI' !important; */
}
i.fa {
  font-family: FontAwesome !important;
}

.index {
  height: 100%;
}
